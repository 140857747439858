import { Head, Layout } from "../components";

export default function About() {
  const title = "Chi siamo",
    description =
      "Supernova è un luogo di cultura, di incontro, di conferenze, di eventi e di workshop, ma anche il salotto dal quale passare a fare quattro chiacchiere.",
    bodyClass = "about";
  return (
    <>
      <Head title={title} description={description} bodyClass={bodyClass} url="/chi-siamo" />
      <h1 className="offscreen">{title}</h1>
      <Layout>
        <>
          <section className="info">
            <div className="c_sizer">
              <h2 className="t_2">Cos'è Supernova?</h2>
              <p>
                Supernova nasce da un'idea di Valeria e Giulia che stufe di sentir ripetere: "uff, a
                Monza non c'è niente?" han deciso di fare qualcosa.
              </p>
              <h2 className="t_2">Che cosa?</h2>
              <p>Supernova è uno spazio che c'era già, ma adesso si veste di nuovo.</p>
              <blockquote>Prima posto celato e ora svelato (o rivelato)</blockquote>
              <p>
                Supernova è un luogo di cultura, di incontro, di conferenze, di eventi e di
                workshop, ma anche il salotto dal quale passare a fare quattro chiacchiere.
              </p>
              <p>Supernova sono le tue idee, le tue proposte e la tua partecipazione attiva.</p>
              <p>Supernova è la stella che fa più bella la tua città.</p>
            </div>
          </section>
          <section className="who">
            <div className="c_sizer">
              <h2 className="t_2">Chi siamo</h2>
              <article>
                <div className="b_profile">
                  <figure className="left round">
                    <img
                      src="assets/img/avatar_vale.jpg"
                      alt="Foto di Valeria De Cicco"
                      height="200px"
                      width="200px"
                    />
                  </figure>
                  <header>
                    <h2>Valeria De Cicco</h2>
                    <h3>Fotografa & Videomaker</h3>
                  </header>
                </div>
                <div className="b_content">
                  <p>
                    Ho un diploma allo IED di Milano con tesi dedicata alle fotografie di food.
                    Inizialmente ho lavorato come assistente di molti fotografi, poi sono scappata
                    negli States per lavoro e proprio per le strade di New York ho iniziato a
                    ritrarre persone e luoghi. <br />
                    Decido poi di tornare in Italia per aprire un mio studio.
                  </p>
                  <p>
                    Lo spazio è nato come studio Fotografico nella primavera del 2012 quando era
                    ancora uno spazio vuoto ma con la volontà di renderlo un luogo stimolante da
                    condividere con altri artisti e fotografi.
                  </p>
                  <p>
                    Da dodici anni fotografo eventi, bellissime location, persone e oggetti. Appena
                    posso, rigenero il cuore e gli occhi viaggiando in giro per il mondo, per
                    tornare alla base con entusiasmo e spunti sempre nuovi.
                  </p>
                  <p>
                    Tra i miei clienti: <br />
                    Bulgari, Morellato, IBM
                  </p>
                </div>
              </article>
            </div>
          </section>
        </>
      </Layout>
    </>
  );
}
