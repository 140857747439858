import { useEffect, useState } from "react";
import { list } from "../api/rest";
import { capitalize, mapTypeToId, slugify } from "../utils/mapper";
import Loader from "./Loader";
import { Link } from "react-router-dom";
export type ListEventProps = {
  type?: string;
  date?: string;
  filter?: string | number;
  mode?: string;
  data?: any;
};

export const ListEvent = ({ type, date, filter, mode, data }: ListEventProps): JSX.Element => {
  const [moreData, setMoreData] = useState<undefined | any>(data ?? undefined);

  useEffect(() => {
    const getData = async () => {
      const events: any = await list(
        `events?join=categories&join=artists&order=date_start,${
          mode === "next" ? "asc" : "desc"
        }&filter=deferred,neq,1${type ? "&filter=category,eq," + mapTypeToId(type) : ""}${
          filter ? "&filter=id,neq," + filter : ""
        }${mode ? "&filter=date_start,ge," + new Date().toISOString() : ""}`
      );
      setMoreData({ events: events.data.records });
    };
    if (data) {
      setMoreData(data);
    }
    if (!moreData && !data) {
      getData();
    }
  }, [data]);
  return (
    <>
      {moreData?.events ? (
        <>
          {moreData.events.length > 0 && (
            <section className={`${type ? type : null} list ${mode ? mode + " cards" : "past"}`}>
              {mode === "next" && <h2 className="t_3 full_row">Prossimi appuntamenti</h2>}
              <>
                {moreData.events.map((event: any) => {
                  let title = `${capitalize(event.category.name)} | ${
                    event.date_start
                      ? capitalize(
                          new Intl.DateTimeFormat("it-IT", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }).format(new Date(event.date_start))
                        ) + " | "
                      : ""
                  }${event.title}${event.artist ? " - " + event.artist_full_name : ""}`;

                  return (
                    <article
                      key={event.id}
                      className={new Date(event.date_start) >= new Date() ? "" : "done"}
                    >
                      <Link
                        to={`/${event.category.name}/${event.id}-${slugify(title)}`}
                        title={title}
                        reloadDocument
                      >
                        <>
                          {mode === "next" ? (
                            <>
                              <figure className="full corner">
                                <img
                                  src={`/assets/${event.category.name}/${event.image_link}`}
                                  alt={title}
                                  width="300px"
                                  height="300px"
                                />
                              </figure>
                            </>
                          ) : (
                            <>
                              <span className="time">
                                <div className="weekday">
                                  {capitalize(
                                    new Intl.DateTimeFormat("it-IT", {
                                      weekday: "long",
                                    }).format(new Date(event.date_start))
                                  )}
                                  {event.date_end &&
                                    " - " +
                                      capitalize(
                                        new Intl.DateTimeFormat("it-IT", {
                                          weekday: "long",
                                        }).format(new Date(event.date_end))
                                      )}
                                </div>
                                <div className="date">
                                  {capitalize(
                                    new Intl.DateTimeFormat("it-IT", {
                                      day: "numeric",
                                      month: "long",
                                    }).format(new Date(event.date_start))
                                  )}
                                  {event.date_end &&
                                    " - " +
                                      capitalize(
                                        new Intl.DateTimeFormat("it-IT", {
                                          day: "numeric",
                                          month: "long",
                                        }).format(new Date(event.date_end))
                                      )}
                                </div>
                                <div className="year">
                                  {new Date(event.date_start).getFullYear()}
                                </div>
                              </span>

                              <span className="title">
                                <div className="author">
                                  {event?.artist_full_name
                                    ? event?.artist_full_name
                                    : event.description}
                                </div>
                                {event.title}
                              </span>
                              {event.tags && (
                                <div className="tags">
                                  {event.tags.split(";").map(
                                    (tag: string) =>
                                      tag && (
                                        <span key={tag} className="tag">
                                          {tag}
                                        </span>
                                      )
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      </Link>
                    </article>
                  );
                })}
              </>
              {/* </div> */}
            </section>
          )}
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
};

export default ListEvent;
