import { Head, Layout, ListEvent } from "../components";

export default function Event() {
  return (
    <>
      <Head
        title="Eventi"
        description="Qui trovi l'elenco di tutti gli eventi che abbiamo organizzato"
        bodyClass="events"
        url="/events"
      />
      <Layout>
        <div className="c_sizer">
          <ListEvent type="events" />
        </div>
      </Layout>
    </>
  );
}
