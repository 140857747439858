import { ChangeEvent, useEffect, useState } from "react";
import { ListEvent, ListArtist } from "../components";
import { list } from "../api/rest";

export default function Search() {
  const [search, setSearch] = useState<string>();
  const [moreData, setMoreData] = useState<undefined | any>();

  useEffect(() => {
    if (search) {
      let events: any = [];
      const getData = async () => {
        events = await list(`events?search=${search}&join=categories&order=date_start,desc`);
        setMoreData({
          events: events.data.records,
        });
      };

      getData();
    }
  }, [search]);
  return (
    <>
      <form className="form-group search-bar" autoComplete="off">
        <input
          type="text"
          placeholder="Cerca nel sito"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            e.target.value.length > 2 && setSearch(encodeURIComponent(e.target.value))
          }
          minLength={3}
          id="search"
          autoComplete="off"
        />
        <label htmlFor="search">Cerca nel sito</label>
      </form>
      {moreData?.events?.length > 0 ? (
        <span className="results">
          Trovat{moreData.events.length > 1 ? "i" : "o"} {moreData.events.length} risultat
          {moreData.events.length > 1 ? "i" : "o"}
        </span>
      ) : (
        moreData?.events && <span className="no-results">Nessun risultato</span>
      )}
      {moreData?.artists && <ListArtist type="artists" data={moreData} />}
      {moreData?.events && <ListEvent type="events" data={moreData} />}
    </>
  );
}
