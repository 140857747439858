import { Helmet } from "react-helmet-async";
export type HeadProps = {
  title: string;
  description?: string;
  bodyClass?: string;
  url?: string;
  image?: string;
};
const Head = ({ title, description, url, image, bodyClass }: HeadProps) => (
  <Helmet title={`${title} | Spazio Supernova`} defaultTitle="Spazio Supernova">
    <meta
      name="description"
      content={
        description ||
        "Studio fotografico, ma anche luogo di incontri, conferenze, eventi e workshop creati per apprendere nuove tecniche legate all'arte e al mondo dell'artigianato, ma anche salotto dal quale passare a fare quattro chiacchiere e scambiarsi idee.Spazio Supernova - via Borgazzi 87/A - 20900 Monza"
      }
    />
    <link rel="canonical" href={`https://spaziosupernova.it${url}`} />
    <meta property="og:title" content={title} />
    <meta
      property="og:description"
      content={
        description ||
        "Studio fotografico, ma anche luogo di incontri, conferenze, eventi e workshop creati per apprendere nuove tecniche legate all'arte e al mondo dell'artigianato, ma anche salotto dal quale passare a fare quattro chiacchiere e scambiarsi idee.Spazio Supernova - via Borgazzi 87/A - 20900 Monza"
      }
    />
    {/* {bodyClass === "contacts" && (
      <link
        href="https://cdn-images.mailchimp.com/embedcode/classic-10_7.css"
        rel="stylesheet"
        type="text/css"
      />
    )} */}
    <meta
      property="og:image"
      content={image ?? "https://www.spaziosupernova.it/assets/img/spnv_square.jpg"}
    />
    <meta property="og:url" content={`https://spaziosupernova.it${url}`} />
    {/* {bodyClass === "contacts" && (
      <style>
        {`#mc_embed_signup{clear:left; }#mc-embedded-subscribe-form input[type=checkbox]{display: inline; width: auto;margin-right: 10px; height: auto;}#mergeRow-gdpr {margin-top: 20px;}#mergeRow-gdpr fieldset label {font-weight: normal;}#mc-embedded-subscribe-form .mc_fieldset{border:none;min-height: 0px;padding-bottom:0px;}.content__gdprLegal{padding: 2rem 0; font-size: 1.2rem;}#mc_embed_signup .mc-field-group input{padding:0;}`}
      </style>
    )} */}
    {bodyClass && <body className={bodyClass} />}
  </Helmet>
);
export default Head;
