export const mapTypeToId = (type: string) => {
  let cat_id;
  switch (type) {
    case "workshops":
      cat_id = 1;
      break;
    case "events":
      cat_id = 2;
      break;
    case "blog":
      cat_id = 3;
      break;
    case "party":
      cat_id = 4;
      break;
    default:
      cat_id = 3;
      break;
  }
  return cat_id;
};

export const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || "";
export const slugify = (text: string) => {
  const illegalRe = /[-.~:/?#[\]@!$&'"()*+,;%=|]/g;
  return text != null && text.length > 0
    ? text
        .replace(illegalRe, "")
        .replace(/\s|_|\(|\)/g, "-")
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toLowerCase()
    : "";
};
