import { Head, Layout, ListEvent, List } from "../components";
import { capitalize } from "../utils/mapper";
import { useEffect, useState } from "react";
import { read } from "../api/rest";
import { useLocation, useParams } from "react-router-dom";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export default function Detail() {
  const location = useLocation();
  const path = useParams<string>();
  const content_id: string = path?.content_id?.split("-")[0] ?? "";
  const [data, setData] = useState<undefined | any>();
  const [title, setTitle] = useState<undefined | any>();
  useEffect(() => {
    const getData = async () => {
      const detail: any = await read("events", content_id + "?join=categories&join=artists");
      setData(detail?.data);
      setTitle(
        `${capitalize(detail.data.category.name)} | ${
          detail.data.date_start ? detail.data.date_start + " | " : ""
        }${detail.data.title}${detail.data?.artist ? " - " + detail.data.artist_full_name : ""}`
      );
    };
    if (!data) {
      getData();
    }
  }, [content_id, data]);
  return (
    <>
      {data && title && (
        <>
          <Head
            title={title}
            description={data.description}
            image={`/assets/${data.category.name}/${data.image_link}`}
            bodyClass="workshops"
            url={location.pathname}
          />
          <h1 className="offscreen">{title}</h1>
        </>
      )}
      <Layout>
        {data && (
          <>
            <section className="workshops">
              <div className="c_sizer">
                <h2 className="t_2">{data.category.name}</h2>
                <article>
                  <div className="details">
                    <header>
                      {(data.date_start || data.event_hours) && (
                        <span className="time">
                          {data.date_end
                            ? new Intl.DateTimeFormat("it-IT", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }).formatRange(new Date(data.date_start), new Date(data.date_end))
                            : new Intl.DateTimeFormat("it-IT", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }).format(new Date(data.date_start))}
                          {data.event_hours && <span>{data.event_hours}</span>}
                        </span>
                      )}
                      <h2>{data.title}</h2>
                      <h3 dangerouslySetInnerHTML={{ __html: data.description }}></h3>
                      {data.artist_full_name && (
                        <span className="author">
                          con <em>{data.artist_full_name}</em>
                        </span>
                      )}
                      <span className="price">
                        {data.price ? `Costo ${data.price} €` : `Evento gratuito`}
                      </span>
                      {new Date(data.date_start) >= new Date() && data.subscribe_link && (
                        <span className="subscribe icon-link">
                          <a
                            href={data.subscribe_link}
                            title={title}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Conferma la tua presenza subito!
                          </a>
                        </span>
                      )}
                      {data.tags && (
                        <div className="tags">
                          {data.tags.split(";").map(
                            (tag: string) =>
                              tag && (
                                <span key={tag} className="tag">
                                  {tag}
                                </span>
                              )
                          )}
                        </div>
                      )}
                    </header>
                    {data.image_link && (
                      <figure className="full corner">
                        <img
                          width="400px"
                          height="400px"
                          fetchpriority="high"
                          alt={title}
                          src={`/assets/${data.category.name}/${data.image_link}`}
                        />
                      </figure>
                    )}
                  </div>

                  <div className="b_content">
                    {data.content && (
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: "<p>" + data.content.replaceAll("<br/><br/>", "</p><p>") + "</p>",
                        }}
                      ></div>
                    )}
                    {data.content_to && (
                      <>
                        <h4>A chi è rivolto?</h4>
                        <p dangerouslySetInnerHTML={{ __html: data.content_to }}></p>
                      </>
                    )}
                    {data.content_what && (
                      <>
                        <h4>Cosa si farà</h4>
                        <p dangerouslySetInnerHTML={{ __html: data.content_what }}></p>
                      </>
                    )}
                    {data.content_hardware && (
                      <>
                        <h4>Materiali compresi</h4>
                        <ul className="hardware">
                          {data.content_hardware
                            .split(";")
                            .map(
                              (hardware: string) => hardware && <li key={hardware}>{hardware}</li>
                            )}
                        </ul>
                      </>
                    )}
                    {data.content_need && (
                      <>
                        <h4>Cosa portare?</h4>
                        {/* <p dangerouslySetInnerHTML={{ __html: data.content_need }}></p> */}
                        <ul className="hardware">
                          {data.content_need
                            .split(";")
                            .map(
                              (need: string) =>
                                need && (
                                  <li key={need} dangerouslySetInnerHTML={{ __html: need }}></li>
                                )
                            )}
                        </ul>
                      </>
                    )}

                    {data.artist && (
                      <>
                        <h4 className="who">
                          Chi è <strong>{data.artist_full_name}</strong>?
                        </h4>
                        <div
                          className="bio"
                          dangerouslySetInnerHTML={{
                            __html:
                              "<p>" + data.artist.bio.replaceAll("<br/><br/>", "</p><p>") + "</p>",
                          }}
                        ></div>
                        {(data.artist.instagram || data.artist.facebook || data.artist.web) && (
                          <div className="socials">
                            {data.artist.instagram &&
                              data.artist.instagram.split("***").map((link: string) => (
                                <a
                                  key={link}
                                  title="Profilo Instagram"
                                  href={link}
                                  rel="noreferrer"
                                  target="_blank"
                                  className="icon-instagram"
                                >
                                  {link.substring(26)}
                                </a>
                              ))}
                            {data.artist.facebook &&
                              data.artist.facebook.split("***").map((link: string) => (
                                <a
                                  key={link}
                                  title="Profilo Facebook"
                                  href={link}
                                  rel="noreferrer"
                                  target="_blank"
                                  className="icon-facebook"
                                >
                                  {link}
                                </a>
                              ))}
                            {data.artist.website &&
                              data.artist.website.split("***").map((link: string) => (
                                <a
                                  key={link}
                                  title="Sito web"
                                  href={link}
                                  rel="noreferrer"
                                  target="_blank"
                                  className="icon-website"
                                >
                                  {link}
                                </a>
                              ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </article>
              </div>
            </section>

            {data.category.id === 1 && (
              <section className="rules workshops">
                <div className="c_sizer">
                  <article>
                    <div className="b_content">
                      <h4>COSTI E ISCRIZIONI</h4>
                      <p>
                        La quota di iscrizione è{" "}
                        <b>{data.price ? `${data.price} €` : `gratuita`}</b>, comprensiva di{" "}
                        {(data.event_hours.substring(8, 10) - data.event_hours.substring(0, 2)) *
                          (data.date_end
                            ? Math.ceil(
                                Math.abs(
                                  new Date(data.date_end).valueOf() -
                                    new Date(data.date_start).valueOf()
                                ) / _MS_PER_DAY
                              ) + 1
                            : 1)}{" "}
                        or
                        {data.event_hours.substring(8, 10) - data.event_hours.substring(0, 2) > 1
                          ? "e"
                          : "a"}{" "}
                        circa di corso intensivo e del materiale utilizzato.
                      </p>
                      {data.participants && (
                        <p>
                          Il workshop sarà attivato con un numero minimo di{" "}
                          <em>{Math.ceil(data.participants / 2) + 2}</em> partecipanti e si terrà
                          con un massimo di <em>{data.participants}</em> persone.
                        </p>
                      )}
                      <p>
                        Per ulteriori informazioni e dettagli scriveteci all'indirizzo
                        <a
                          className="icon-mail-b"
                          href={`mailto:info@spaziosupernova.it?subject=Info Workshop ${data.title}`}
                        >
                          info@spaziosupernova.it
                        </a>
                      </p>
                      {new Date(data.date_start) >= new Date() && (
                        <>
                          <h4>POLICY DI CANCELLAZIONE AL WORKSHOP</h4>
                          <p>
                            È possibile rinunciare all'iscrizione entro 4 giorni di calendario dalla
                            data di inizio del corso, informando Spazio Supernova all'indirizzo
                            <a
                              className="icon-mail-b"
                              href={`mailto:info@spaziosupernova.it?subject=Cancellazione Workshop ${data.title}`}
                            >
                              info@spaziosupernova.it
                            </a>
                            <br />
                            La quota versata verrà interamente restituita.
                            <br />
                            La mancata comunicazione entro i termini stabiliti prevede la perdita
                            della quota versata.
                          </p>
                        </>
                      )}
                    </div>
                  </article>
                </div>
              </section>
            )}
            {data.artist && <List type="artists" filter={data.artist.id} />}
            <ListEvent type={data.category.name} filter={content_id} mode="next" />
          </>
        )}
      </Layout>
    </>
  );
}
