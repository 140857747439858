import { useState } from "react";

export type ListEventProps = {
  type?: string;
  date?: string;
  filter?: string | number;
  mode?: string;
  data?: any;
};

export const ListArtist = ({ type, date, filter, mode, data }: ListEventProps) => {
  const [moreData, _setMoreData] = useState<undefined | any>(data ?? undefined);

  return moreData?.artists ? (
    <section className={`${type} list ${mode ? mode + " cards" : "past"}`}>
      {moreData.artists.map((artist: any) => {
        let title = artist.full_name;

        return (
          <article key={artist.id}>
            <span className="author">{title}</span>
            <ul className="events">
              {artist.events.map((events: any) => (
                <li key={events.id}>{events.title}</li>
              ))}
            </ul>
          </article>
        );
      })}
      {/* </div> */}
    </section>
  ) : null;
};

export default ListArtist;
