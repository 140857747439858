import { Head, Layout, Search } from "../components";

export default function NoMatch() {
  return (
    <>
      <Head
        title="Contenuto non trovato"
        description="Ops, questo contenuto non è più disponibile"
        bodyClass="events"
        url="/not-found"
      />
      <Layout>
        <div className="c_sizer">
          <h2 className="not-found">Contenuto non trovato, prova a fare una ricerca</h2>

          <Search />
        </div>
      </Layout>
    </>
  );
}
