import { Head, Layout, ListEvent } from "../components";

export default function Workshop() {
  return (
    <>
      <Head
        title="Workshop"
        description="Qui trovi l'elenco di tutti i laboratori che abbiamo organizzato"
        bodyClass="workshops"
        url="/workshops"
      />

      <Layout>
        <div className="c_sizer">
          <ListEvent type="workshops" />
        </div>
      </Layout>
    </>
  );
}
