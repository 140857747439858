import { Link } from "react-router-dom";
import Menu from "./Menu";

export const Header = () => (
  <header>
    <div className="c_sizer">
      <Link to="/" title="Home | Spazio Supernova" className="logo">
        <img
          width="295px"
          height="100px"
          src={`/assets/img/sprnv_logo_color.svg`}
          alt="Spazio Supernova"
        />
      </Link>
      <Menu />
    </div>
  </header>
);

export default Header;
