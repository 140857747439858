import { Head, Layout } from "../components";

export default function Gallery() {
  return (
    <>
      <Head title="Galleria" bodyClass="gallery" description="Gente di Supernova!" url="/gallery" />
      <Layout>
        <section className={`flez-home list cards`}>
          <h2 style={{ color: "#000", fontSize: "1.6rem", width: "100%" }}>GALLERY</h2>
          <div className="">
            {[
              {
                id: 10000,
                title: "Gente di Supernova",
                link: "",
                category: { name: "img" },
                image_link: "ph_bimbi.jpg",
                image_link_large: "ph_bimbi.jpg",
              },
              {
                id: 10001,
                title: "Gente di Supernova",
                link: "",
                category: { name: "img" },
                image_link: "ph_librokilo.jpg",
                image_link_large: "ph_librokilo.jpg",
              },
              {
                id: 10002,
                title: "Gente di Supernova",
                link: "",
                category: { name: "img" },
                image_link: "ph_workshop.jpg",
                image_link_large: "ph_workshop.jpg",
              },
            ].map((event: any) => (
              <article key={event.id} title={event.title}>
                {/* <a href={event.link} title={event.title} target="_blank"> */}
                <picture className="full corner">
                  <source
                    srcSet={`/assets/${event.category.name}/${event.image_link_large}`}
                    media="(min-width:1000px)"
                    title={event.title}
                    src={`/assets/${event.category.name}/${event.image_link_large}`}
                    height={700}
                    width={1000}
                  />
                  <source
                    srcSet={`/assets/${event.category.name}/${event.image_link}`}
                    media="(min-width:200px)"
                    src={`/assets/${event.category.name}/${event.image_link}`}
                    height={300}
                    width={300}
                  />
                  <img
                    alt={event.title}
                    src={`/assets/${event.category.name}/${event.image_link}`}
                  />
                </picture>
                {/* </a> */}
              </article>
            ))}
          </div>
        </section>
      </Layout>
    </>
  );
}
