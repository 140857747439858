export const Footer = () => (
  <>
    <div className="gang_starr">
      <div className="icon-star-b"></div>
      <div className="icon-star-b"></div>
      <div className="icon-star-b"></div>
    </div>
    <footer>
      <div className="c_sizer">
        <div id="colophon">
          <span className="t_copy">
            Copyright &copy; {new Date().getFullYear()} | Spazio Supernova ASD | CF 94640870153 |
            Via Borgazzi 87/A Monza
          </span>
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
