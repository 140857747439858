import axios from "axios";
const baseApi = "https://spaziosupernova.it/api/api.php/records";
export type JSONdata = {
  [key: string]: unknown;
};

export interface JSONRecordData {
  records: JSONdata[];
}

const headers = { "Access-Control-Allow-Origin": "true" };

export const create = async (type: string, data: any): Promise<JSONdata> => {
  return axios.post(`${baseApi}/${type}`, data, { headers: headers });
};
export const read = async (type: string, id: string): Promise<JSONdata> => {
  return axios.get(`${baseApi}/${type}/${id}`);
};
export const list = async (type: string): Promise<JSONRecordData> => {
  return axios.get(`${baseApi}/${type}`);
};
