import { Head, Layout } from "../components";

export default function Contact() {
  const title = "Contatti",
    description = "Siamo in Via Borgazzi 87/A a Monza, si riceve solo su appuntamento",
    bodyClass = "contacts";
  return (
    <>
      <Head title={title} description={description} bodyClass={bodyClass} url="/contatti" />
      <h1 className="offscreen">{title}</h1>
      <Layout>
        <>
          <section className="address">
            <div className="c_sizer flez-home ">
              <h2 className="t_2">Contatti</h2>
              <div
                className="full"
                style={{
                  margin: "2rem auto",
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <ul className="location">
                  <li>
                    <span className="label">Spazio Supernova</span>
                    <span>
                      Via Borgazzi 87/A
                      <br />
                      20900 - Monza
                    </span>
                    <span>
                      Si riceve solo su appuntamento
                      <br />
                    </span>
                    <a
                      href="https://goo.gl/maps/4jfToyH5m7N2"
                      rel="noreferrer"
                      target="_blank"
                      title="Come
        raggiungerci"
                    >
                      <span>Vedi su Google Maps</span>
                    </a>
                  </li>
                  <li>
                    <span className="label">Email</span>
                    <a
                      href="mailto:info@spaziosupernova.it?subject=Richiesta%20informazioni"
                      rel="noreferrer"
                      target="_blank"
                      title="Supernova Mail"
                    >
                      <span>info@spaziosupernova.it</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="three contacts">
                <figure className="full">
                  <img src="assets/img/sprnv_map.jpg" alt="Mappa - Spazio Supernova" />
                </figure>
                <figure className="full">
                  <img
                    src="assets/img/ingresso.jpg"
                    alt="Entrata da Via Borgazzi - Spazio Supernova"
                  />
                </figure>
                <figure className="full">
                  <img
                    src="assets/img/supernova-porta.jpg"
                    alt="Ingresso dal condominio - Spazio Supernova"
                  />
                </figure>
              </div>
            </div>
          </section>
          <section className="address newsletter">
            <div className="c_sizer">
              {
                //Begin Mailchimp Signup Form
              }
              <h2 className="t_2">Newsletter</h2>
              <span>Lasciaci i tuoi dati per informarti sulle nostre attività spaziali!</span>
              <div id="mc_embed_signup">
                <form
                  action="https://spaziosupernova.us19.list-manage.com/subscribe/post?u=27c9d80a1068367956096ca67&amp;amp;id=4e566e99b2"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  target="_blank"
                  autoComplete="off"
                >
                  <div id="mc_embed_signup_scroll">
                    <div className="form-group required">
                      <input className="required email" type="email" name="EMAIL" id="mce-EMAIL" />
                      <label htmlFor="mce-EMAIL">Email</label>
                    </div>
                    <div className="form-group">
                      <input type="text" name="FNAME" id="mce-FNAME" />
                      <label htmlFor="mce-FNAME">Nome</label>
                    </div>
                    <div className="form-group">
                      <input type="text" name="LNAME" id="mce-LNAME" />
                      <label htmlFor="mce-LNAME">Cognome</label>
                    </div>
                    <div
                      className="mergeRow gdpr-mergeRow content__gdprBlock form-group"
                      id="mergeRow-gdpr"
                    >
                      <div className="content__gdpr">
                        <p>
                          Per ricevere le nostre comunicazioni, abbiamo bisogno del tuo consenso
                          almeno per l'invio delle mail
                        </p>
                        <div className="mc_fieldset gdprRequired form-group">
                          <label className="checkbox subfield required" htmlFor="gdpr_28963">
                            <input
                              className="av-checkbox gdpr"
                              type="checkbox"
                              id="gdpr_28963"
                              name="gdpr[28963]"
                              value="Y"
                              required
                            />
                            sì, avete il mio consenso per inviarmi mail <sup>(obbligatorio)</sup>
                          </label>
                          <label className="checkbox subfield" htmlFor="gdpr_28967">
                            <input
                              className="av-checkbox gdpr"
                              type="checkbox"
                              id="gdpr_28967"
                              name="gdpr[28967]"
                              value="Y"
                            />
                            sì, avete il mio consenso per campagne di marketing
                          </label>
                          <div className="indicates-required">
                            <span className="asterisk">*</span>obbligatorio
                          </div>
                        </div>

                        <button
                          className="button"
                          type="submit"
                          value="Iscriviti"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                        >
                          ISCRIVITI
                        </button>
                      </div>
                      <div className="clear" id="mce-responses">
                        <p>
                          Puoi modificare i tuoi consensi in qualsiasi momento, seguendo il link
                          presente nel footer delle nostre email.
                        </p>
                        <div className="content__gdprLegal">
                          <p>
                            Utilizziamo Mailchimp come nostra piattaforma di newsletter.
                            Iscrivendoti, acconsenti che i tuoi dati vengano trasferiti a Mailchimp
                            per poter utilizzare il servizio.{" "}
                            <a href="https://mailchimp.com/legal/" target="_blank" rel="noreferrer">
                              Qui trovi più informazioni su come vengono gestiti i tuoi dati
                            </a>
                          </p>
                        </div>
                        <div
                          className="response"
                          id="mce-error-response"
                          style={{ display: "none" }}
                        ></div>
                        <div
                          className="response"
                          id="mce-success-response"
                          style={{ display: "none" }}
                        ></div>
                      </div>
                      <div
                        style={{ position: "absolute", left: "-5000px" }}
                        aria-hidden="true"
                        aria-label="Check control"
                      >
                        <label htmlFor="formcheck">check input</label>
                        <input
                          type="text"
                          id="formcheck"
                          name="b_27c9d80a1068367956096ca67_4e566e99b2"
                          tabIndex={0}
                          value=""
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {
                //End mc_embed_signup
              }
            </div>
          </section>
        </>
      </Layout>
    </>
  );
}
