import { useEffect, useState } from "react";
import { list } from "../api/rest";
import { capitalize, slugify } from "../utils/mapper";
import { Link, useParams } from "react-router-dom";

export type ListProps = {
  type?: string;
  date?: string;
  filter?: string | number;
  mode?: string;
};

export const List = ({ type, date, filter, mode }: ListProps) => {
  const path = useParams<string>();
  const content_id: string = path?.content_id?.split("-")[0] ?? "";
  const [moreData, setMoreData] = useState<undefined | any>();

  useEffect(() => {
    const getData = async () => {
      const events: any = await list(
        `events?join=categories&join=artists&order=date_start,desc&filter=artist,eq,${filter}${
          content_id ? `&filter=id,neq,${content_id}` : ``
        }`
      );
      setMoreData({ events: events.data.records.length > 0 ? events.data.records : undefined });
    };
    if (!moreData) {
      getData();
    }
  }, []);
  return (
    <>
      {moreData?.events
        ? moreData.events.length > 0 && (
            <section className={`${type} list past`}>
              <div className="c_sizer">
                <h2 className="t_6 full_row">
                  Altre occasioni in cui abbiamo ospitato{" "}
                  <em>{moreData.events[0].artist_full_name}</em>
                </h2>

                {moreData.events.map((event: any) => {
                  let title = `${capitalize(event.category.name)} | ${
                    event.date_start
                      ? capitalize(
                          new Intl.DateTimeFormat("it-IT", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }).format(new Date(event.date_start))
                        ) + " | "
                      : ""
                  }${event.title}${event.id ? " - " + event.artist_full_name : ""}`;

                  return (
                    <article
                      key={event.id}
                      className={new Date(event.date_start) >= new Date() ? "" : "done"}
                    >
                      <Link
                        to={`/${event.category.name}/${event.id}-${slugify(title)}`}
                        title={title}
                        reloadDocument
                      >
                        <span className="time">
                          <div className="weekday">
                            {capitalize(
                              new Intl.DateTimeFormat("it-IT", {
                                weekday: "long",
                              }).format(new Date(event.date_start))
                            )}
                          </div>
                          <div className="date">
                            {capitalize(
                              new Intl.DateTimeFormat("it-IT", {
                                day: "numeric",
                                month: "long",
                              }).format(new Date(event.date_start))
                            )}
                          </div>
                          <div className="year">{new Date(event.date_start).getFullYear()}</div>
                        </span>

                        <span className="title">
                          <div className="author">{event.artist_full_name}</div>
                          {event.title}
                        </span>
                      </Link>
                    </article>
                  );
                })}
              </div>
            </section>
          )
        : null}
    </>
  );
};

export default List;
