import { ReactNode } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Loader } from ".";
export type LayoutProps = {
  children?: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => (
  <>
    <Header />
    {children ? children : <Loader />}
    <Footer />
  </>
);

export default Layout;
