import React from "react";
import { BrowserRouter } from "react-router-dom";
import { SiteRoutes } from "./routes";

export default function App() {
  return (
    <React.Suspense fallback={null}>
      <BrowserRouter basename="/">
        <SiteRoutes />
      </BrowserRouter>
    </React.Suspense>
  );
}
